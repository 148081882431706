import React, { useState, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import ScrollAnimation from "../components/ScrollAnimation";
import { useTranslation } from "react-i18next";

// Images
// Impresión en Lona
import Impresion1 from "../images/catalogs/Impresion/Impresion1.webp";
import Impresion2 from "../images/catalogs/Impresion/Impresion2.webp";
import Impresion3 from "../images/catalogs/Impresion/Impresion3.webp";
import Impresion4 from "../images/catalogs/Impresion/Impresion4.webp";
import Impresion5 from "../images/catalogs/Impresion/Impresion5.webp";
import Impresion6 from "../images/catalogs/Impresion/Impresion6.webp";
import Impresion7 from "../images/catalogs/Impresion/Impresion7.webp";
import Impresion8 from "../images/catalogs/Impresion/Impresion8.webp";

// Vallas Moviles
import VMGeneral from "../images/catalogs/VM/VMGeneral.webp";
import VM1 from "../images/catalogs/VM/VM1.webp";
import VM2 from "../images/catalogs/VM/VM2.webp";
import VM3 from "../images/catalogs/VM/VM3.webp";
import VM4 from "../images/catalogs/VM/VM4.webp";
import VM5 from "../images/catalogs/VM/VM5.webp";
import VM6 from "../images/catalogs/VM/VM6.webp";
import VM7 from "../images/catalogs/VM/VM7.webp";
import VM8 from "../images/catalogs/VM/VM8.webp";
import VM9 from "../images/catalogs/VM/VM9.webp";

// Espectaculares
import Espectaculares1 from "../images/catalogs/Espectaculares/Espectaculares1.webp";
import Espectaculares2 from "../images/catalogs/Espectaculares/Espectaculares2.webp";
import Espectaculares3 from "../images/catalogs/Espectaculares/Espectaculares3.webp";
import Espectaculares4 from "../images/catalogs/Espectaculares/Espectaculares4.webp";
import Espectaculares5 from "../images/catalogs/Espectaculares/Espectaculares5.webp";
import Espectaculares6 from "../images/catalogs/Espectaculares/Espectaculares6.webp";

// Anuncios Luminosos
import ALGeneral from "../images/catalogs/AL/ALGeneral.webp";
import AL1 from "../images/catalogs/AL/AL1.webp";
import AL2 from "../images/catalogs/AL/AL2.webp";
import AL4 from "../images/catalogs/AL/AL4.webp";
import AL5 from "../images/catalogs/AL/AL5.webp";
import AL6 from "../images/catalogs/AL/AL6.webp";
import AL7 from "../images/catalogs/AL/AL7.webp";
import AL8 from "../images/catalogs/AL/AL8.webp";
import AL9 from "../images/catalogs/AL/AL9.webp";

// Rotulacion
import RotulacionGeneral from "../images/catalogs/Rotulacion/RotulacionGeneral.webp";
import Rotulacion1 from "../images/catalogs/Rotulacion/Rotulacion1.webp";
import Rotulacion2 from "../images/catalogs/Rotulacion/Rotulacion2.webp";
import Rotulacion3 from "../images/catalogs/Rotulacion/Rotulacion3.webp";
import Rotulacion4 from "../images/catalogs/Rotulacion/Rotulacion4.webp";
import Rotulacion5 from "../images/catalogs/Rotulacion/Rotulacion5.webp";
import Rotulacion6 from "../images/catalogs/Rotulacion/Rotulacion6.webp";
import Rotulacion7 from "../images/catalogs/Rotulacion/Rotulacion7.webp";
import Rotulacion8 from "../images/catalogs/Rotulacion/Rotulacion8.webp";
import Rotulacion9 from "../images/catalogs/Rotulacion/Rotulacion9.webp";

// BTLS
import BTLS1 from "../images/catalogs/BTLS/BTLS1.webp";
import BTLS2 from "../images/catalogs/BTLS/BTLS2.webp";
import BTLS3 from "../images/catalogs/BTLS/BTLS3.webp";
import BTLS4 from "../images/catalogs/BTLS/BTLS4.webp";
import BTLS5 from "../images/catalogs/BTLS/BTLS5.webp";

export default function Catalog() {
  const [selectedPage, setSelectedPage] = useState(0);
  const buttonRefs = useRef([]);
  const { t } = useTranslation();

  const options = [
    {
      page: 0,
      name: t("general"),
      images: [
        Impresion2,
        VMGeneral,
        Espectaculares4,
        RotulacionGeneral,
        BTLS1,
        ALGeneral,
      ],
      generalOptions: [
        t("impresionLona"),
        t("vallasMoviles"),
        t("espectaculares"),
        t("Rotulacion"),
        t("BTLS"),
        t("anunciosLuminosos"),
      ],
    },
    {
      page: 1,
      name: t("impresionLona"),
      images: [
        Impresion1,
        Impresion2,
        Impresion3,
        Impresion4,
        Impresion5,
        Impresion6,
        Impresion7,
        Impresion8,
      ],
    },
    {
      page: 2,
      name: t("vallasMoviles"),
      images: [VM1, VM2, VM3, VM4, VM5, VM6, VM7, VM8, VM9],
    },
    {
      page: 3,
      name: t("espectaculares"),
      images: [
        Espectaculares1,
        Espectaculares2,
        Espectaculares3,
        Espectaculares4,
        Espectaculares5,
        Espectaculares6,
      ],
    },
    {
      page: 4,
      name: t("Rotulacion"),
      images: [
        Rotulacion1,
        Rotulacion2,
        Rotulacion3,
        Rotulacion4,
        Rotulacion5,
        Rotulacion6,
        Rotulacion7,
        Rotulacion8,
        Rotulacion9,
      ],
    },
    {
      page: 5,
      name: t("BTLS"),
      images: [BTLS1, BTLS2, BTLS3, BTLS4, BTLS5],
    },
    {
      page: 6,
      name: t("luminosos"),
      images: [AL1, AL2, ALGeneral, AL4, AL5, AL6, AL7, AL8, AL9],
    },
  ];

  const handleButtonClick = (index) => {
    setSelectedPage(index);
    buttonRefs.current[index]?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "center",
    });
  };

  console.log(selectedPage);

  return (
    <ScrollAnimation
      id="catalog"
      className="container py-5 d-flex flex-column justify-content-center align-items-center"
      ariaLabelledBy="catalog-title"
    >
      <h2 id="catalog-title" className="text-center">
        {t("catalogTitle")} <span>{t("catalogTitle2")}</span>
      </h2>
      <div className="w-100 d-flex justify-content-between align-items-center gap-3 overflow-auto">
        {options.map((option, index) => (
          <button
            key={option.page}
            type="button"
            className={`btn primary-font btn-primary catalog-option ${
              selectedPage === option.page ? "active" : "inactive"
            }`}
            onClick={() => {
              handleButtonClick(option.page);
            }}
            ref={(el) => (buttonRefs.current[index] = el)}
            aria-pressed={selectedPage === option.page}
          >
            {option.name}
          </button>
        ))}
      </div>
      <AnimatePresence mode="wait">
        <motion.div
          key={selectedPage}
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -10, opacity: 0 }}
          transition={{ duration: 0.2 }}
          className="image-collage p-3"
        >
          {options[selectedPage].images.map((image, index) => {
            return (
              <div
                className={selectedPage === 0 ? "clickable image" : "image"}
                onClick={() => {
                  if (selectedPage === 0) {
                    handleButtonClick(index + 1);
                  }
                }}
                key={index}
                role={selectedPage === 0 ? "button" : undefined}
                tabIndex={selectedPage === 0 ? 0 : undefined}
              >
                <img
                  src={image}
                  alt={`${options[selectedPage].name} ${index + 1}`}
                  loading="lazy"
                />
                {selectedPage === 0 && (
                  <p className="w-100 p-2 bg-primary image-label text-center m-0">
                    {options[selectedPage].generalOptions[index]}
                  </p>
                )}
              </div>
            );
          })}
        </motion.div>
      </AnimatePresence>
    </ScrollAnimation>
  );
}
