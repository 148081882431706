// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    en: {
        translation: {
            services: ''
        }
    },
    es: {
        translation: {
            services: 'Servicios',
            clients: 'Clientes',
            mission: 'Misión y Visión',
            catalog: 'Catálogo',
            map: 'Mapa',
            contactUs: 'Contáctanos',
            hero: 'Tu',
            hero2: 'marca',
            hero3: 'donde debe de',
            hero4: 'estar',
            espectaculares: 'Espectaculares',
            espectacularesDescription: 'Date a conocer en nuestros diferentes puntos, depende de tu mercado te asignamos el espectacular que mejor te convenga ¡pregunta hoy a nuestros asesores la mejor opción para ti y tu negocio!',
            catalogTitle: "Catálago de",
            catalogTitle2: "Servicios",
            impresionLona: 'Impresión en Lona y Vinil',
            vallasMoviles: 'Vallas Móviles',
            vallasMovilesDescription: '¿Quieres que todos vean tu publicidad en la calle? ¿Quieres que más gente te conozca? Anúnciate en nuestras vallas móviles, tenemos diferentes rutas y puntos. Pregunta a nuestros asesores.',
            luminosos: 'Luminosos',
            anunciosLuminosos: 'Anuncios Luminosos',
            anunciosLuminososDescription: 'Anuncios luminosos para tu negocio, exterior e interior. Diseño personalizado, en diferentes materiales dependiendo de lo que tu negocio requiera.',
            rotulaciones: 'Rotulaciones',
            rotulacionesDescription: 'La rotulación de vehículos es uno de los servicios más solicitados, por los grandes beneficios que ofrece a empresas y marcas. El principal: una presencia continuada y móvil.',
            BTLS: 'BTLS',
            BTLSDescription: 'Aumenta la cantidad de personas que conocen tu producto/Servicio con una campaña de publicidad masiva. BTL móvil o fijo, depende de lo que tu negocio necesite.',
            servicesTitle: 'Conoce Nuestros',
            servicesTitle2: 'Servicios',
            clientsTitle: 'Clientes',
            clientsTitle2: 'Distinguidos',
            missionTitle: 'Nuestra',
            missionTitle2: 'Misión',
            missionDescription: 'Crear y diseñar mensajes visuales e imagen corporativa, para satisfacer las necesidades de nuestros clientes, ofreciendo eficiencia con calidad y rapidez, utilizando tecnología de punta, buscando alcanzar el liderazgo. En el ramo de la publicidad exterior en la Región Noroeste como primera etapa.',
            visionTitle: 'Nuestra',
            visionTitle2: 'Visión',
            visionDescription: 'Ser la empresa líder en publicidad exterior, publicidad móvil e imagen corporativa en la Región Noroeste, trabajando bajo los más altos estándares de calidad y ofreciendo la mayor tecnología, en el mejor servicio y una amplia gama de productos, ayudando con ello al cumplimiento de los objetivos de nuestros clientes.',
            general: 'General',
            faqTitle: 'Preguntas',
            faqTitle2: 'Frecuentes',
            question1: '¿PUEDEN RECOMENDAR IDEAS PARA LA PRESENTACIÓN DE MI PRODUCTO?',
            answer1: 'Contamos con un gran equipo de especialistas que lo asesoran para que cuente con la mejor opción en la presentación de su producto.',
            question2: '¿CUÁL ES EL PLAZO DE ENTREGA?',
            answer2: 'Los tiempos de producción varían según los acabados y cantidades solicitadas. Usted encontrará una estimación de plazo de entrega personalizada de acuerdo a sus necesidades.',
            question3: '¿PRESTAN SERVICIOS FUERA DE SONORA?',
            answer3: 'Sí, contamos con servicio de envío a toda la república, contacte a un asesor para cotizar la mejor opción de paqutería y precio.',
            question4: '¿EL DISEÑO VA INCLUIDO?',
            answer4: 'Contacte a nuestros asesores para seleccionar la mejor opción que se ajuste a su proyecto',
            question5: '¿DÓNDE TIENEN ESPECTACULARES?',
            answer5: 'Contamos con más de 60 puntos de Hermosillo, 3 sitios en Nogales y uno en Obregón.',
            mapTitle: 'Nuestros',
            mapTitle2: 'Espectaculares',
            mapDescription: 'Haz click para mostrar información específica de cada espectacular',
            code: 'Código',
            size: 'Tamaño',
            downloadPdf: 'Ver Ficha',
            contactTitle: 'Habla con',
            contactTitle2: 'Nosotros',
            name: 'Nombre',
            email: 'Correo Electrónico',
            subject: 'Asunto',
            message: 'Mensaje',
            send: 'Enviar',
            sending: 'Enviando...',
            footerText: 'El objetivo de todo publicista es conocer el mercado mejor que la competencia y poner ese conocimiento en manos de escritores y artistas con imaginación y una profunda comprensión de las cuestiones humanas.',
            contact: 'Contacto',
            visit: 'Visítanos',
            allRightsReserved: 'Todos los derechos Reservados',
            messageSent: 'Mensaje enviado correctamente',
            messageError: 'Error al enviar el mensaje',
        }
    }
};


i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "es", // default language
        fallbackLng: "es", // language to use if the current one is not available
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;