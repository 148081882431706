import React from "react";
import { scrollToSection } from "../helpers";
import { useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ScrollAnimation from "../components/ScrollAnimation";

// Client Logos
import Deproyco from "../images/clients/Logo_Deproyco.webp";
import Nissan from "../images/clients/Logo_Nissan.webp";
import Telcel from "../images/clients/Logo_Telcel.webp";
import Virgo from "../images/clients/Logo_Virgo.webp";
import Arturo from "../images/clients/Logo_Arturo.webp";
import Barba from "../images/clients/Logo_Barba.webp";
import Masterfuel from "../images/clients/Logo_Masterfuel.webp";

export default function Clients() {
  const { t } = useTranslation();

  const clientLogos = [
    { id: 0, name: "Deproyco", img: Deproyco },
    { id: 1, name: "Nissan", img: Nissan },
    { id: 2, name: "Telcel", img: Telcel },
    { id: 3, name: "Virgo", img: Virgo },
    { id: 4, name: "Arturo", img: Arturo },
    { id: 5, name: "Barba", img: Barba },
    { id: 6, name: "Masterfuel", img: Masterfuel },
    { id: 0, name: "Deproyco", img: Deproyco },
    { id: 1, name: "Nissan", img: Nissan },
    { id: 2, name: "Telcel", img: Telcel },
    { id: 3, name: "Virgo", img: Virgo },
    { id: 4, name: "Arturo", img: Arturo },
    { id: 5, name: "Barba", img: Barba },
    { id: 6, name: "Masterfuel", img: Masterfuel },
  ];

  // Carousel responsive settings
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4, // Number of logos per slide on desktop
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3, // Number of logos per slide on tablet
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 3, // Number of logos per slide on mobile
      slidesToSlide: 1,
    },
  };

  return (
    <ScrollAnimation
      id="clients"
      className="container d-flex flex-column justify-content-center align-items-center gap-3 pb-5"
      ariaLabelledBy="clients-title"
    >
      <h2 id="clients-title" className="text-center mb-3">
        <span>{t("clientsTitle")} </span>
        {t("clientsTitle2")}
      </h2>
      <div className="w-100 pt-3 pb-5">
        <Carousel
          responsive={responsive}
          autoPlay={true}
          autoPlaySpeed={3000}
          infinite={true}
          keyBoardControl={true}
          customTransition="transform 1000ms ease-in-out"
          containerClass="carousel-container"
          removeArrowOnDeviceType={["tablet", "mobile"]}
          customLeftArrow={<></>}
          customRightArrow={<></>}
        >
          {clientLogos.map((client) => (
            <div
              key={client.id}
              className="h-100 d-flex justify-content-center align-items-center"
              aria-labelledby={`client-${client.id}`}
            >
              <img
                src={client.img}
                alt={client.name}
                className="clientLogo"
                loading="lazy"
                aria-hidden="true" // Use for decorative images if appropriate
              />
              <span id={`client-${client.id}`} className="visually-hidden">
                {client.name}
              </span>
            </div>
          ))}
        </Carousel>
      </div>
      <button
        className="btn"
        type="button"
        onClick={(e) => scrollToSection(e, "contact")}
      >
        {t("contactUs")}
      </button>
    </ScrollAnimation>
  );
}
