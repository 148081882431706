import React from "react";
import ScrollAnimation from "../components/ScrollAnimation";
import { useTranslation } from "react-i18next";

export default function Mission() {
  const { t } = useTranslation();

  return (
    <ScrollAnimation
      id="mission"
      className="container d-flex flex-column flex-md-row justify-content-between align-items-start py-5"
      ariaLabelledBy="mission-title"
    >
      <div className="w-md-50 px-3 px-md-5 py-3 border-dashed d-flex flex-column justify-content-between align-items-center align-items-md-start">
        <h2 id="mission-title" className="text-light missionTitle">
          {t("missionTitle")} <span>{t("missionTitle2")}</span>
        </h2>
        <p className="text-light second-font text-center text-md-start">
          {t("missionDescription")}
        </p>
      </div>
      <div className="w-md-50 px-3 px-md-5 pt-5 py-md-3 d-flex flex-column justify-content-between align-items-center align-items-md-start">
        <h2 className="text-light missionTitle">
          {t("visionTitle")} <span>{t("visionTitle2")}</span>
        </h2>
        <p className="text-light second-font text-center text-md-start">
          {t("visionDescription")}
        </p>
      </div>
    </ScrollAnimation>
  );
}
