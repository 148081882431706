import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ScrollAnimation from "../components/ScrollAnimation";

import { useTranslation } from "react-i18next";
import { scrollToSection } from "../helpers";

export default function FAQ() {
  const [expanded, setExpanded] = useState(0);
  const { t } = useTranslation();

  const faqs = [
    { id: 0, question: t("question1"), answer: t("answer1") },
    { id: 1, question: t("question2"), answer: t("answer2") },
    { id: 2, question: t("question3"), answer: t("answer3") },
    { id: 3, question: t("question4"), answer: t("answer4") },
    { id: 4, question: t("question5"), answer: t("answer5") },
  ];

  const styles = {
    accordion: {
      borderRadius: "5px",
      border: "none",
      boxShadow: "none",
      margin: "0 !important",
    },
    questionHeader: {
      backgroundColor: "#f79e2d",
      cursor: "pointer",
      color: "#fff",
      margin: "0 !important",
      padding: "0.4rem 1.6rem",
      borderRadius: "5px 5px 0 0",
    },
    inactiveHeader: {
      backgroundColor: "transparent",
      padding: "0.4rem 1.6rem",
      borderBottom: "1px solid #f79e2d",
      borderRadius: "5px",
    },
    questionAnswer: {
      backgroundColor: "#fafafa",
      padding: "2rem",
      borderRadius: "5px",
    },
  };

  return (
    <ScrollAnimation
      id="faq"
      className="container pb-5 d-flex flex-column justify-content-center align-items-center"
      ariaLabelledBy="faq-title"
    >
      <h2 id="faq-title" className="text-center mb-3">
        {t("faqTitle")} <span>{t("faqTitle2")}</span>
      </h2>
      <div className="pb-5 pt-md-5 d-flex flex-column w-100">
        {faqs.map((faq) => (
          <Accordion
            expanded={expanded === faq.id}
            onChange={() => setExpanded(faq.id === expanded ? -1 : faq.id)}
            sx={styles.accordion}
            key={faq.id}
          >
            <AccordionSummary
              aria-controls={`panel${faq.id}-content`}
              id={`panel${faq.id}-header`}
              expandIcon={
                <ExpandMoreIcon
                  sx={{ color: expanded === faq.id ? "#ffffff" : "#f79e2d" }}
                />
              }
              sx={
                expanded === faq.id
                  ? styles.questionHeader
                  : styles.inactiveHeader
              }
            >
              <p className="m-0 question-header">{faq.question}</p>
            </AccordionSummary>
            <AccordionDetails sx={styles.questionAnswer}>
              <p className="m-0 second-font question-answer">{faq.answer}</p>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
      <button
        className="btn"
        type="button"
        onClick={(e) => scrollToSection(e, "contact")}
      >
        {t("contactUs")}
      </button>
    </ScrollAnimation>
  );
}
