import React, { useRef, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

import PublimarkLogo from "../images/publimark.webp";
import { scrollToSection } from "../helpers";

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: "circle(0px at 400px -10px)",
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
};

function MobileNavBar({ items, isModalOpen, handleToggleModal, modalRef }) {
  if(!isModalOpen) {
    return null;
  }

  return (
    <>
      <div className="modal-backdrop" aria-hidden="true"></div>
      <motion.nav
        animate={isModalOpen ? "open" : "closed"}
        variants={sidebar}
        className="d-flex side-modal flex-column align-items-start p-3"
        ref={modalRef}
        aria-label="Mobile Navigation Menu"
      >
        {/* Close button for the modal */}
        <motion.button
          className="close-button navbar-toggler p-2 bg-primary"
          aria-label="Close navigation menu"
          onClick={handleToggleModal}
        >
          <i className="bi bi-x-lg" aria-hidden="true"></i>
        </motion.button>

        {/* Mobile Logo */}
        <a className="navbar-brand-mobile" href="/" aria-label="Publimark Home">
          <img
            className="img-fluid logo"
            src={PublimarkLogo}
            alt="Publimark logo"
            loading="lazy"
          />
        </a>

        {/* Mobile Navigation Links */}
        <ul className="w-100 navbar-nav d-flex flex-column align-items-start mt-5">
          {items.map((item) => (
            <li key={item.id} className="w-100">
              <button
                className="w-100 nav-link text-nowrap d-flex justify-content-between align-items-center"
                type="button"
                onClick={(e) => {
                  scrollToSection(e, item.url);
                  handleToggleModal();
                }}
                aria-label={`Go to ${item.name} section`}
              >
                {item.name}
                <i className="bi bi-arrow-right" aria-hidden="true"></i>
              </button>
            </li>
          ))}
        </ul>
      </motion.nav>
    </>
  );
}

export default function Navbar({ setIsModalOpen, isModalOpen }) {
  const { t } = useTranslation();
  const modalRef = useRef(null);

  const NavBarItems = [
    { id: 0, name: t("services"), url: "services" },
    {
      id: 1,
      name: t("clients"),
      url: "clients",
    },
    {
      id: 2,
      name: t("mission"),
      url: "mission",
    },
    {
      id: 3,
      name: t("catalog"),
      url: "catalog",
    },
    {
      id: 4,
      name: t("map"),
      url: "map",
    },
  ];

  const handleToggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleClickOutside = useCallback(
    (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsModalOpen(false);
      }
    },
    [setIsModalOpen, modalRef]
  );

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = "hidden";
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.body.style.overflow = "unset";
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isModalOpen, handleClickOutside]);

  return (
    <nav className="navbar navbar-expand-lg bg-transparent py-md-3">
      <div className="container">
        <a className="navbar-brand" href="/" aria-label="Publimark Home">
          <img
            className="img-fluid logo"
            src={PublimarkLogo}
            alt="Publimark logo"
            loading="lazy"
          />
        </a>

        {/* Toggle button for small screens */}
        <motion.button
          className="navbar-toggler p-2 bg-primary"
          type="button"
          aria-controls="navbarNav"
          aria-expanded={isModalOpen}
          aria-label={
            isModalOpen ? "Close navigation menu" : "Open navigation menu"
          }
          onClick={handleToggleModal}
        >
          <i className="bi bi-list hamburgerIcon" aria-hidden="true"></i>
        </motion.button>

        {/* Mobile Navigation */}
        <MobileNavBar
          items={NavBarItems}
          isModalOpen={isModalOpen}
          handleToggleModal={handleToggleModal}
          modalRef={modalRef}
        />

        {/* Desktop Navigation */}
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto gap-3 d-flex justify-content-around align-items-center">
            {NavBarItems.map((item) => (
              <li key={item.id}>
                <button
                  className="nav-link text-nowrap"
                  type="button"
                  onClick={(e) => scrollToSection(e, item.url)}
                  aria-label={`Go to ${item.name} section`}
                >
                  {item.name}
                </button>
              </li>
            ))}
            <li>
              <button
                className="btn"
                type="button"
                onClick={(e) => scrollToSection(e, "contact")}
                aria-label="Go to Contact Us section"
              >
                {t("contactUs")}
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
