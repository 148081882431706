import React from "react";

import { useTranslation } from "react-i18next";
import ScrollAnimation from "../components/ScrollAnimation";

// icons
import vallasMoviles from "../images/icons/vm.webp";
import espectaculares from "../images/icons/espectaculares.webp";
import anunciosLuminosos from "../images/icons/al.webp";
import rotulaciones from "../images/icons/rotulaciones.webp";
import BTLS from "../images/icons/btls.webp";


export default function Services() {
  const { t } = useTranslation();

  const serviceItems = [
    {
      id: 0,
      name: t("vallasMoviles"),
      description: t("vallasMovilesDescription"),
      icon: vallasMoviles,
    },
    {
      id: 1,
      name: t("espectaculares"),
      description: t("espectacularesDescription"),
      icon: espectaculares,
    },
    {
      id: 2,
      name: t("anunciosLuminosos"),
      description: t("anunciosLuminososDescription"), 
      icon: anunciosLuminosos,
    },
    {
      id: 3,
      name: t("rotulaciones"),
      description: t("rotulacionesDescription"),
      icon: rotulaciones,
    },
    {
      id: 4,
      name: t("BTLS"),
      description: t("BTLSDescription"),
      icon: BTLS,
    },
  ];

  return (
    <ScrollAnimation
      id="services"
      className="container py-5"
      ariaLabelledBy="services-title"
    >
      <h2 id="services-title" className="text-center mb-3">
        {t("servicesTitle")} <span>{t("servicesTitle2")}</span>
      </h2>
      <div className="d-flex flex-column justify-content-center align-items-center gap-3 pt-3">
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center gap-3">
          {serviceItems.slice(0, 3).map((service) => (
            <div
              key={service.id}
              className="card py-2 px-4 py-md-4 d-flex flex-column justify-content-center align-items-center"
              aria-labelledby={`service-name-${service.id}`}
            >
              <img
                src={service.icon}
                alt={service.name}
                className="serviceIcon"
                loading="lazy"
              />
              <p id={`service-name-${service.id}`} className="text-center">
                {service.name}
              </p>
              <p className="text-center second-font">{service.description}</p>
            </div>
          ))}
        </div>
        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center gap-3">
          {serviceItems.slice(3, 5).map((service) => (
            <div
              key={service.id}
              className="card py-2 px-4 py-md-4 d-flex flex-column justify-content-center align-items-center"
              aria-labelledby={`service-name-${service.id}`}
            >
              <img
                src={service.icon}
                alt={service.name}
                className="serviceIcon"
                loading="lazy"
              />
              <p id={`service-name-${service.id}`} className="text-center">
                {service.name}
              </p>
              <p className="text-center second-font">{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </ScrollAnimation>
  );
}
