import React, {useEffect, useState} from 'react';
import emailjs from "@emailjs/browser";
import { Toaster } from "react-hot-toast";
import { motion, useScroll, useSpring } from 'framer-motion';

// components
import WhatsappButton from './components/WhatsappButton';

// Sections
import Navbar from './sections/Navbar';
import Hero from './sections/Hero';
import Services from './sections/Services';
import Clients from './sections/Clients';
import Mission from './sections/Mission';
import Catalog from './sections/Catalog';
import FAQ from './sections/FAQ';
import Map from './sections/Map'
import Contact from './sections/Contact';
import Footer from './sections/Footer';

export default function App() {
  const { scrollYProgress } = useScroll();
  const [isModalOpen, setIsModalOpen] = useState(false);


  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001
  });

  useEffect(() => {
    emailjs.init(process.env.REACT_APP_EMAILJS_PUBLICKEY);
  }, []);

  return (
    <main className="App">
      <Toaster />
      <div className='top-bg bg-opacity-25 border-bottom border-5 border-primary-subtle'>
        <motion.div className="progress-bar" style={{ scaleX }} />
        <div className="overlay"></div>
        <Navbar setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen}/>
        <Hero isModalOpen={isModalOpen} />
      </div>
      <Services />
      <Clients />
      <div className='mission-bg bg-opacity-25 border-top border-bottom border-5 border-primary-subtle'>
        <Mission />
      </div>
      <Catalog />
      <FAQ />
      <Map />
      <div className='contact-bg bg-opacity-25 border-top border-5 border-primary-subtle'>
        <Contact />
      </div>
      <div className='footer-bg bg-opacity-25 border-top border-5 border-primary-subtle'>
        <Footer />
      </div>
      <WhatsappButton />
    </main>
  );
}
