import React from "react";
import { motion } from "framer-motion";

export default function WhatsappButton() {
  return (
    <motion.a
      href="https://wa.me/+5216622829924"
      className="whatsapp-button"
      target="_blank"
      rel="noreferrer"
      aria-label="Chat with us on WhatsApp"
      whileHover={{
        scale: 1.1,
      }}
      whileTap={{ scale: 0.9 }}
      transition={{ type: "spring", stiffness: 300 }}
    >
      <i
        className="bi bi-whatsapp"
        style={{ fontSize: "2rem" }}
        aria-hidden="true"
      />
    </motion.a>
  );
}

