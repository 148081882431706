import React from "react";
import { useTranslation } from "react-i18next";

import PublimarkLogo from "../images/publimark.webp";

export default function Footer() {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  return (
    <footer className="container py-5 d-flex flex-column justify-content-center align-items-start gap-3">
      <div className="w-100 d-flex flex-column flex-md-row justify-content-between align-items-center gap-4">
        <div className="d-flex flex-column align-items-center justify-content-center align-items-md-start gap-3 col-md-4">
          <img
            className="img-fluid logo"
            src={PublimarkLogo}
            alt="Publimark Logo"
          />
          <p className="footer-text m-0 text-center text-md-start">
            {t("footerText")}
          </p>
        </div>
        <div className="col-md-4 h-100 d-flex flex-column justify-content-between gap-3">
          <h3 className="text-center text-md-start">{t("contact")}</h3>
          <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-start align-items-center align-items-md-start gap-3">
            <i className="bi bi-telephone-fill" aria-hidden="true" />
            <div className="d-flex flex-column justify-content-center gap-1">
              <a href="tel:+526623106626" aria-label="Call (662) 310-6626">
                <p className="m-0">(662) 310-6626</p>
              </a>
              <a href="tel:+526623194578" aria-label="Call (662) 319-4578">
                <p className="m-0">(662) 319-4578</p>
              </a>
              <a href="tel:+526621410727" aria-label="Call (662) 141-0727">
                <p className="m-0">(662) 141-0727</p>
              </a>
            </div>
          </div>
          <address>
            <a
              href="mailto:ventas.publimarkmedios@gmail.com"
              className="d-flex flex-column flex-md-row justify-content-center justify-content-md-start align-items-center align-items-md-start gap-3"
              aria-label="Email ventas.publimarkmedios@gmail.com"
            >
              <i className="bi bi-envelope-fill" aria-hidden="true" />
              <p className="m-0">ventas.publimarkmedios@gmail.com</p>
            </a>
          </address>
        </div>
        <div className="col-md-4 d-flex flex-column justify-content-center justify-content-md-start align-items-center align-items-md-start gap-3">
          <h3 className="text-center text-md-start">{t("visit")}</h3>
          <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-start align-items-center align-items-md-start gap-3">
            <i className="bi bi-clock-fill" aria-hidden="true" />
            <div>
              <p className="m-0 text-center text-md-start">
                L-V: 8:30 AM - 1:30 PM
              </p>
              <p className="m-0 text-center text-md-start">3:30 PM - 7:00 PM</p>
            </div>
          </div>
          <div className="d-flex flex-column flex-md-row justify-content-center justify-content-md-start align-items-center align-items-md-start gap-3">
            <i className="bi bi-geo-alt-fill" aria-hidden="true" />
            <div>
              <a
                href="https://maps.app.goo.gl/LtXj6VXSZpGWNoDa7"
                aria-label="View location on map"
              >
                <p className="m-0 text-center text-md-start">
                  Veracruz No. 55 Esq. Enrique García Sanchez, Col. San Benito
                </p>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="w-100 d-flex flex-column flex-md-row justify-content-md-between align-items-md-center gap-3 pt-3 pt-md-0">
        <div className="d-flex justify-content-center justify-content-md-between align-content-center gap-4">
          <a
            href="https://www.instagram.com/publimark.medios/"
            aria-label="Visit our Instagram"
          >
            <i className="footer-icon bi bi-instagram" aria-hidden="true" />
          </a>
          <a
            href="https://www.facebook.com/publimark.medios"
            aria-label="Visit our Facebook"
          >
            <i className="footer-icon bi bi-facebook" aria-hidden="true" />
          </a>
          <a href="https://wa.me/+5216622829924" aria-label="Chat on WhatsApp">
            <i className="footer-icon bi bi-whatsapp" aria-hidden="true" />
          </a>
          <a
            href="mailto:ventas.publimarkmedios@gmail.com"
            aria-label="Send us an email"
          >
            <i className="footer-icon bi bi-envelope-fill" />
          </a>
        </div>
        <p className="m-0 text-center">
          &copy; {currentYear} | Publimark | {t("allRightsReserved")}
        </p>
      </div>
    </footer>
  );
}
