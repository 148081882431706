import React from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

// Icons
import Arrow from "../images/icons/arrow.svg";

export default function Hero({ isModalOpen }) {
  const { t } = useTranslation();
  return (
    <motion.header
      className="container d-flex w-100 bg-transparent flex-column justify-content-end align-items-center pb-5"
      style={!isModalOpen ? { position: "inherit" } : { position: "static" }}
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
      viewport={{ once: true }}
    >
      <h1 className="text-center" aria-label={t("hero")}>
        {t("hero")}
        <span> {t("hero2")} </span>
        {t("hero3")} <span> {t("hero4")}</span>
      </h1>
      <img
        className="arrowIcon"
        src={Arrow}
        alt="Arrow pointing downwards"
        loading="lazy"
      />
    </motion.header>
  );
}
