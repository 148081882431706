import React, { useState } from "react";
import { Oval } from "react-loader-spinner";
import toast from "react-hot-toast";
import emailjs from "@emailjs/browser";
import ScrollAnimation from "../components/ScrollAnimation";

import { useTranslation } from "react-i18next";

export default function Contact() {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    const serviceId = process.env.REACT_APP_EMAILJS_SERVICEID;
    const templateId = process.env.REACT_APP_EMAILJS_TEMPLATEID;
    const formValues = {
      name: form.name.value,
      email: form.email.value,
      subject: form.subject.value,
      message: form.message.value,
    };

    try {
      setLoading(true);
      await emailjs.send(serviceId, templateId, formValues);
      toast.success(t("messageSent"));
      form.reset();
    } catch (error) {
      console.error(error);
      toast.error(t("messageError"));
    } finally {
      setLoading(false);
    }
  };

  return (
    <ScrollAnimation
      id="contact"
      className="container py-5 d-flex flex-column justify-content-center align-items-center"
      ariaLabelledBy="contact-title"
    >
      <h2 className="text-center text-white">
        {t("contactTitle")} <span>{t("contactTitle2")}</span>
      </h2>
      <form
        className="w-100 d-flex flex-column justify-content-between align-items-center gap-3"
        onSubmit={handleSubmit}
      >
        {loading && (
          <div className="loaderContainer">
            <Oval color="#7ef2e7" height={50} width={50} className="loader" />
          </div>
        )}
        <div className="w-100 d-flex flex-column flex-md-row justify-content-between align-items-center gap-3">
          <div className="w-100 d-flex flex-column justify-content-center align-items-start">
            <label htmlFor="name">{t("name")}*</label>
            <input
              className="w-100 border-0 p-2"
              type="text"
              id="name"
              name="name"
              placeholder={t("name")}
              required
              aria-label={t("name")}
            />
          </div>
          <div className="w-100 d-flex flex-column justify-content-center align-items-start">
            <label htmlFor="email">{t("email")}*</label>
            <input
              className="w-100 border-0 p-2"
              type="email"
              id="email"
              name="email"
              placeholder={t("email")}
              required
              aria-label={t("email")}
            />
          </div>
        </div>
        <div className="w-100 d-flex flex-column justify-content-center align-items-start">
          <label htmlFor="subject">{t("subject")}*</label>
          <input
            className="w-100 border-0 p-2"
            type="text"
            id="subject"
            name="subject"
            placeholder={t("subject")}
            required
            aria-label={t("subject")}
          />
        </div>
        <div className="w-100 d-flex flex-column justify-content-center align-items-start">
          <label htmlFor="message">{t("message")}*</label>
          <textarea
            className="w-100 border-0 p-2"
            id="message"
            name="message"
            placeholder={t("message")}
            required
            aria-label={t("message")}
          ></textarea>
        </div>
        <button
          className={`btn ${loading ? "disabled" : ""}`}
          disabled={loading}
          type="submit"
        >
          {loading ? t("sending") : t("send")}
        </button>
      </form>
    </ScrollAnimation>
  );
}
