import React, { useCallback, useState } from "react";
import {
  GoogleMap,
  useLoadScript,
  InfoBox,
  MarkerF,
} from "@react-google-maps/api";
import ScrollAnimation from "../components/ScrollAnimation";

import { useTranslation } from "react-i18next";

import PinIcon from "../images/icons/pin.svg";

// Images
import Image52 from "../images/billboards/52.webp";
import Image53 from "../images/billboards/53.webp";
import Image000 from "../images/billboards/Image000.webp";
import Image005 from "../images/billboards/Image005.webp";
import Image300 from "../images/billboards/300.webp";
import Image301 from "../images/billboards/301.webp";
import Image302 from "../images/billboards/302.webp";
import Image303 from "../images/billboards/303.webp";
import Image304 from "../images/billboards/304.webp";
import Image305 from "../images/billboards/305.webp";
import Image306 from "../images/billboards/306.webp";
import Image307 from "../images/billboards/307.webp";
import Image308 from "../images/billboards/308.webp";
import Image309 from "../images/billboards/309.webp";
import Image310 from "../images/billboards/310.webp";
import Image311 from "../images/billboards/311.webp";
import Image312 from "../images/billboards/312.webp";
import Image011 from "../images/billboards/Image011.webp";
import Image012 from "../images/billboards/Image012.webp";
import Image02 from "../images/billboards/Image02.webp";
import Image061 from "../images/billboards/Image061.webp";
import Image062 from "../images/billboards/Image062.webp";
import Image081 from "../images/billboards/Image081.webp";
import Image082 from "../images/billboards/Image082.webp";
import Image101 from "../images/billboards/Image101.webp";
import Image102 from "../images/billboards/Image102.webp";
import Image121 from "../images/billboards/Image121.webp";
import Image191 from "../images/billboards/Image191.webp";
import Image192 from "../images/billboards/Image192.webp";
import Image261 from "../images/billboards/Image261.webp";
import Image262 from "../images/billboards/Image262.webp";
import Image391 from "../images/billboards/Image391.webp";
import Image392 from "../images/billboards/Image392.webp";
import Image401 from "../images/billboards/Image401.webp";
import Image402 from "../images/billboards/Image402.webp";
import Image441 from "../images/billboards/Image441.webp";
import Image442 from "../images/billboards/Image442.webp";
import Image471 from "../images/billboards/Image471.webp";
import Image472 from "../images/billboards/Image472.webp";
import Image481 from "../images/billboards/Image481.webp";
import Image482 from "../images/billboards/Image482.webp";
import Image501 from "../images/billboards/Image501.webp";
import Image502 from "../images/billboards/Image502.webp";
import Image511 from "../images/billboards/Image511.webp";
import Image512 from "../images/billboards/Image512.webp";
import Image521 from "../images/billboards/Image521.webp";
import Image522 from "../images/billboards/Image522.webp";
import Image531 from "../images/billboards/Image531.webp";
import Image532 from "../images/billboards/Image532.webp";
import Image561 from "../images/billboards/Image561.webp";
import Image562 from "../images/billboards/Image562.webp";
import Image571 from "../images/billboards/Image571.webp";
import Image572 from "../images/billboards/Image572.webp";
import Image591 from "../images/billboards/Image591.webp";
import Image592 from "../images/billboards/Image592.webp";
import Image611 from "../images/billboards/Image611.webp";
import Image612 from "../images/billboards/Image612.webp";
import Image641 from "../images/billboards/Image641.webp";
import Image642 from "../images/billboards/Image642.webp";
import Image661 from "../images/billboards/Image661.webp";
import Image662 from "../images/billboards/Image662.webp";
import Image721 from "../images/billboards/Image721.webp";
import Image722 from "../images/billboards/Image722.webp";
import Image751 from "../images/billboards/Image751.webp";
import Image752 from "../images/billboards/Image752.webp";
import Image021 from "../images/billboards/Image021.webp";
import Image022 from "../images/billboards/Image022.webp";
import Image051 from "../images/billboards/Image051.webp";
import Image052 from "../images/billboards/Image052.webp";
import Image031 from "../images/billboards/Image031.webp";
import Image032 from "../images/billboards/Image032.webp";
import Image011DOS from "../images/billboards/Image011DOS.webp";
import Image012DOS from "../images/billboards/Image012DOS.webp";
import Image061DOS from "../images/billboards/Image061DOS.webp";
import Image062DOS from "../images/billboards/Image062DOS.webp";
import Image071DOS from "../images/billboards/Image071DOS.webp";
import Image072DOS from "../images/billboards/Image072DOS.webp";
import Image101DOS from "../images/billboards/Image101DOS.webp";
import Image102DOS from "../images/billboards/Image102DOS.webp";
import Image121DOS from "../images/billboards/Image121DOS.webp";
import Image122 from "../images/billboards/Image122.webp";
import Image111 from "../images/billboards/Image111.webp";
import Image112 from "../images/billboards/Image112.webp";

// Pdfs
import Pdf000 from "../pdfs/Pdf000.pdf";
import Pdf005 from "../pdfs/Pdf005.pdf";
import Pdf52 from "../pdfs/52.pdf";
import Pdf53 from "../pdfs/53.pdf";
import Pdf300 from "../pdfs/300.pdf";
import Pdf301 from "../pdfs/301.pdf";
import Pdf302 from "../pdfs/302.pdf";
import Pdf303 from "../pdfs/303.pdf";
import Pdf304 from "../pdfs/304.pdf";
import Pdf305 from "../pdfs/305.pdf";
import Pdf306 from "../pdfs/306.pdf";
import Pdf307 from "../pdfs/307.pdf";
import Pdf308 from "../pdfs/308.pdf";
import Pdf309 from "../pdfs/309.pdf";
import Pdf310 from "../pdfs/310.pdf";
import Pdf311 from "../pdfs/311.pdf";
import Pdf312 from "../pdfs/312.pdf";
import Pdf011 from "../pdfs/Pdf011.pdf";
import Pdf012 from "../pdfs/Pdf012.pdf";
import Pdf02 from "../pdfs/Pdf02.pdf";
import Pdf061 from "../pdfs/Pdf061.pdf";
import Pdf062 from "../pdfs/Pdf062.pdf";
import Pdf081 from "../pdfs/Pdf081.pdf";
import Pdf082 from "../pdfs/Pdf082.pdf";
import Pdf101 from "../pdfs/Pdf101.pdf";
import Pdf102 from "../pdfs/Pdf102.pdf";
import Pdf121 from "../pdfs/Pdf121.pdf";
import Pdf191 from "../pdfs/Pdf191.pdf";
import Pdf192 from "../pdfs/Pdf192.pdf";
import Pdf261 from "../pdfs/Pdf261.pdf";
import Pdf262 from "../pdfs/Pdf262.pdf";
import Pdf391 from "../pdfs/Pdf391.pdf";
import Pdf392 from "../pdfs/Pdf392.pdf";
import Pdf401 from "../pdfs/Pdf401.pdf";
import Pdf402 from "../pdfs/Pdf402.pdf";
import Pdf441 from "../pdfs/Pdf441.pdf";
import Pdf442 from "../pdfs/Pdf442.pdf";
import Pdf471 from "../pdfs/Pdf471.pdf";
import Pdf472 from "../pdfs/Pdf472.pdf";
import Pdf481 from "../pdfs/Pdf481.pdf";
import Pdf482 from "../pdfs/Pdf482.pdf";
import Pdf501 from "../pdfs/Pdf501.pdf";
import Pdf502 from "../pdfs/Pdf502.pdf";
import Pdf511 from "../pdfs/Pdf511.pdf";
import Pdf512 from "../pdfs/Pdf512.pdf";
import Pdf521 from "../pdfs/Pdf521.pdf";
import Pdf522 from "../pdfs/Pdf522.pdf";
import Pdf531 from "../pdfs/Pdf531.pdf";
import Pdf532 from "../pdfs/Pdf532.pdf";
import Pdf561 from "../pdfs/Pdf561.pdf";
import Pdf562 from "../pdfs/Pdf562.pdf";
import Pdf571 from "../pdfs/Pdf571.pdf";
import Pdf572 from "../pdfs/Pdf572.pdf";
import Pdf591 from "../pdfs/Pdf591.pdf";
import Pdf592 from "../pdfs/Pdf592.pdf";
import Pdf611 from "../pdfs/Pdf611.pdf";
import Pdf612 from "../pdfs/Pdf612.pdf";
import Pdf641 from "../pdfs/Pdf641.pdf";
import Pdf642 from "../pdfs/Pdf642.pdf";
import Pdf661 from "../pdfs/Pdf661.pdf";
import Pdf662 from "../pdfs/Pdf662.pdf";
import Pdf721 from "../pdfs/Pdf721.pdf";
import Pdf722 from "../pdfs/Pdf722.pdf";
import Pdf751 from "../pdfs/Pdf751.pdf";
import Pdf752 from "../pdfs/Pdf752.pdf";
import Pdf021 from "../pdfs/Pdf021.pdf";
import Pdf022 from "../pdfs/Pdf022.pdf";
import Pdf051 from "../pdfs/Pdf051.pdf";
import Pdf052 from "../pdfs/Pdf052.pdf";
import Pdf031 from "../pdfs/Pdf031.pdf";
import Pdf032 from "../pdfs/Pdf032.pdf";
import Pdf011DOS from "../pdfs/Pdf011DOS.pdf";
import Pdf012DOS from "../pdfs/Pdf012DOS.pdf";
import Pdf061DOS from "../pdfs/Pdf061DOS.pdf";
import Pdf062DOS from "../pdfs/Pdf062DOS.pdf";
import Pdf071DOS from "../pdfs/Pdf071DOS.pdf";
import Pdf072DOS from "../pdfs/Pdf072DOS.pdf";
import Pdf101DOS from "../pdfs/Pdf101DOS.pdf";
import Pdf102DOS from "../pdfs/Pdf102DOS.pdf";
import Pdf121DOS from "../pdfs/Pdf121DOS.pdf";
import Pdf122 from "../pdfs/Pdf122.pdf";
import Pdf111 from "../pdfs/Pdf111.pdf";
import Pdf112 from "../pdfs/Pdf112.pdf";

export default function Map() {
  const { t } = useTranslation();
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [center, setCenter] = useState({
    lat: 29.092047,
    lng: -110.957617,
  });

  const mapContainerStyle = {
    width: "100%",
    height: "40rem",
    borderRadius: "1rem",
  };

  const mapOptions = {
    disableDefaultUI: true, // Hides all HUD items like zoom controls and map type selector
    zoomControl: false, // Adds back only the zoom control
    styles: [
      {
        featureType: "poi",
        stylers: [{ visibility: "off" }], // Hides points of interest
      },
      {
        featureType: "road",
        elementType: "geometry",
        stylers: [{ color: "#ffffff" }], // Custom road color
      },
      {
        featureType: "water",
        stylers: [{ color: "#bde0ff" }], // Custom water color
      },
    ],
  };

  const billboardLocations = [
    {
      id: 0,
      location: { lat: 29.080464, lng: -110.958505 },
      image: Image310,
      code: "MARK-HMO-310",
      size: "11.3 x 7.6 MTS",
      pdf: Pdf310,
    },
    {
      id: 1,
      location: { lat: 29.10087, lng: -110.915803 },
      image: Image309,
      code: "MARK-HMO-309",
      size: "11 x 7 MTS",
      pdf: Pdf309,
    },
    {
      id: 2,
      location: { lat: 29.0853, lng: -110.990778 }, // Iguales
      image: Image52,
      code: "MARK-HMO-52",
      size: "12.00 x 3.6 MTS",
      pdf: Pdf52,
    },
    {
      id: 3,
      location: { lat: 29.0853, lng: -110.990778 }, // Iguales
      image: Image53,
      code: "MARK-HMO-53",
      size: "12.00 x 3.6 MTS",
      pdf: Pdf53,
    },
    {
      id: 4,
      location: { lat: 29.085694, lng: -110.969315 }, // Iguales
      image: Image311,
      code: "MARK-HMO-311",
      size: "12.90 x 7.2 MTS",
      pdf: Pdf311,
    },
    {
      id: 5,
      location: { lat: 29.085694, lng: -110.969315 }, // Iguales
      image: Image312,
      code: "MARK-HMO-312",
      size: "12.90 x 7.2 MTS",
      pdf: Pdf312,
    },
    {
      id: 6,
      location: { lat: 29.054002, lng: -110.963594 },
      image: Image300,
      code: "MARK-HMO-300",
      size: "10.00 x 6.0 MTS",
      pdf: Pdf300,
    },
    {
      id: 7,
      location: { lat: 29.103411, lng: -110.992506 },
      image: Image302,
      code: "MARK-HMO-302",
      size: "12.00 x 4 MTS",
      pdf: Pdf302,
    },
    {
      id: 8,
      location: { lat: 29.094411, lng: -111.006607 },
      image: Image301,
      code: "MARK-HMO-301",
      size: "12.00 x 6.0 MTS",
      pdf: Pdf301,
    },
    {
      id: 9,
      location: { lat: 29.123056, lng: -110.98863 }, // Iguales
      image: Image303,
      code: "MARK-HMO-303",
      size: "12.00 x 6.0 MTS",
      pdf: Pdf303,
    },
    {
      id: 10,
      location: { lat: 29.123056, lng: -110.98863 }, // Iguales
      image: Image304,
      code: "MARK-HMO-304",
      size: "12.00 x 3.0 MTS",
      pdf: Pdf304,
    },
    {
      id: 11,
      location: { lat: 29.110669, lng: -110.966312 },
      image: Image306,
      code: "MARK-HMO-306",
      size: "12.00 x 4 MTS",
      pdf: Pdf306,
    },
    {
      id: 12,
      location: { lat: 29.11324, lng: -110.970788 },
      image: Image305,
      code: "MARK-HMO-305",
      size: "9.00 x 6.0 MTS",
      pdf: Pdf305,
    },
    {
      id: 13,
      location: { lat: 29.178233, lng: -110.885201 }, // Iguales
      image: Image307,
      code: "MARK-SNP-307",
      size: "6.00 x 7.0 MTS",
      pdf: Pdf307,
    },
    {
      id: 14,
      location: { lat: 29.178233, lng: -110.885201 }, // Iguales
      image: Image308,
      code: "MARK-SNP-308",
      size: "6.00 x 7.0 MTS",
      pdf: Pdf308,
    },
    {
      id: 15,
      location: {
        lat: 29.0976663,
        lng: -110.9447897,
      },
      image: Image011,
      code: "E01-HMO-01-1",
      size: "12.90 X 7.20",
      pdf: Pdf011,
    },
    {
      id: 16,
      location: {
        lat: 29.0976663,
        lng: -110.9447897,
      },
      image: Image012,
      code: "E01-HMO-01-2",
      size: "12.90 X 7.20",
      pdf: Pdf012,
    },
    {
      id: 17,
      location: {
        lat: 22.7708555,
        lng: -102.5832426,
      },
      image: Image02,
      code: "E01-HMO-02",
      size: "12.00 X 6 .50",
      pdf: Pdf02,
    },
    {
      id: 18,
      location: {
        lat: 20.5837952,
        lng: -103.3887853,
      },
      image: Image061,
      code: "E01-HMO-06-1",
      size: "12.00 X 7 .50",
      pdf: Pdf061,
    },
    {
      id: 19,
      location: {
        lat: 20.5837952,
        lng: -103.3887853,
      },
      image: Image062,
      code: "E01-HMO-06-2",
      size: "12.00 X 7 .50",
      pdf: Pdf062,
    },
    {
      id: 20,
      location: {
        lat: 29.1075349,
        lng: -110.9923053,
      },
      image: Image081,
      code: "E01-HMO-08-1",
      size: "9.00 X 6.00",
      pdf: Pdf081,
    },
    {
      id: 21,
      location: {
        lat: 29.1075349,
        lng: -110.9923053,
      },
      image: Image082,
      code: "E01-HMO-08-2",
      size: "8.00 X 7.00",
      pdf: Pdf082,
    },
    {
      id: 22,
      location: {
        lat: 29.0988163,
        lng: -110.984671,
      },
      image: Image101,
      code: "E01-HMO-10-1",
      size: "6.00 X 7.00",
      pdf: Pdf101,
    },
    {
      id: 23,
      location: {
        lat: 29.0988163,
        lng: -110.984671,
      },
      image: Image102,
      code: "E01-HMO-10-2",
      size: "6.00 X 7.00",
      pdf: Pdf102,
    },
    {
      id: 24,
      location: {
        lat: 29.0687051,
        lng: -110.997414,
      },
      image: Image121,
      code: "E01-HMO-12-1",
      size: "10.00 X 5.00",
      pdf: Pdf121,
    },
    {
      id: 25,
      location: {
        lat: 29.084573,
        lng: -110.957572,
      },
      image: Image191,
      code: "E01-HMO-19-1",
      size: "12.00 X 4.00",
      pdf: Pdf191,
    },
    {
      id: 26,
      location: {
        lat: 29.084573,
        lng: -110.957572,
      },
      image: Image192,
      code: "E01-HMO-19-2",
      size: "12.00 X 4.00",
      pdf: Pdf192,
    },
    {
      id: 27,
      location: {
        lat: 29.091291,
        lng: -111.040749,
      },
      image: Image261,
      code: "E01-HMO-26-1",
      size: "12.00 X 7.50",
      pdf: Pdf261,
    },
    {
      id: 28,
      location: {
        lat: 29.091291,
        lng: -111.040749,
      },
      image: Image262,
      code: "E01-HMO-26-2",
      size: "12.00 X 7.50",
      pdf: Pdf262,
    },
    {
      id: 29,
      location: {
        lat: 29.083258,
        lng: -110.951264,
      },
      image: Image391,
      code: "E01-HMO-39-1",
      size: "12.90 X 7.20",
      pdf: Pdf391,
    },
    {
      id: 30,
      location: {
        lat: 29.083258,
        lng: -110.951264,
      },
      image: Image392,
      code: "E01-HMO-39-2",
      size: "12.90 X 7.20",
      pdf: Pdf392,
    },
    {
      id: 31,
      location: {
        lat: 29.1327656,
        lng: -110.9525469,
      },
      image: Image401,
      code: "E01-HMO-40-1",
      size: "12.00 X 8.00 ",
      pdf: Pdf401,
    },
    {
      id: 32,
      location: {
        lat: 29.1327656,
        lng: -110.9525469,
      },
      image: Image402,
      code: "E01-HMO-40-2",
      size: "12.00 X 8.00 ",
      pdf: Pdf402,
    },
    {
      id: 33,
      location: {
        lat: 29.153778,
        lng: -110.909754,
      },
      image: Image441,
      code: "E01-HMO-44-1",
      size: "12.00 X 4.00",
      pdf: Pdf441,
    },
    {
      id: 34,
      location: {
        lat: 29.153778,
        lng: -110.909754,
      },
      image: Image442,
      code: "E01-HMO-44-2",
      size: "12.00 X 4.00",
      pdf: Pdf442,
    },
    {
      id: 35,
      location: {
        lat: -12.1311771,
        lng: -76.9902022,
      },
      image: Image471,
      code: "E01-HMO-47-1",
      size: "12.90 X 7.20",
      pdf: Pdf471,
    },
    {
      id: 36,
      location: {
        lat: -12.1311771,
        lng: -76.9902022,
      },
      image: Image472,
      code: "E01-HMO-47-2",
      size: "12.90 X 7.20",
      pdf: Pdf472,
    },
    {
      id: 37,
      location: {
        lat: 29.097763,
        lng: -110.94487,
      },
      image: Image481,
      code: "E01-HMO-48-1",
      size: "12.00 X 5.50",
      pdf: Pdf481,
    },
    {
      id: 38,
      location: {
        lat: 29.097763,
        lng: -110.94487,
      },
      image: Image482,
      code: "E01-HMO-48-2",
      size: "12.00 X 5.50",
      pdf: Pdf482,
    },
    {
      id: 39,
      location: {
        lat: 29.1107759,
        lng: -110.9510303,
      },
      image: Image501,
      code: "E01-HMO-50-1",
      size: "12.90 x 7.20",
      pdf: Pdf501,
    },
    {
      id: 40,
      location: {
        lat: 29.1107759,
        lng: -110.9510303,
      },
      image: Image502,
      code: "E01-HMO-50-2",
      size: "12.90 x 7.20",
      pdf: Pdf502,
    },
    {
      id: 41,
      location: {
        lat: 29.0944443,
        lng: -111.0023557,
      },
      image: Image511,
      code: "E01-HMO-51-1",
      size: "12.90 X 7.20",
      pdf: Pdf511,
    },
    {
      id: 42,
      location: {
        lat: 29.0944443,
        lng: -111.0023557,
      },
      image: Image512,
      code: "E01-HMO-51-2",
      size: "12.90 X 7.20",
      pdf: Pdf512,
    },
    {
      id: 43,
      location: {
        lat: 29.1380909,
        lng: -110.9951286,
      },
      image: Image521,
      code: "E01-HMO-52-1",
      size: "12.90 X 5.50",
      pdf: Pdf521,
    },
    {
      id: 44,
      location: {
        lat: 29.1380909,
        lng: -110.9951286,
      },
      image: Image522,
      code: "E01-HMO-52-2",
      size: "12.90 X 5.50",
      pdf: Pdf522,
    },
    {
      id: 45,
      location: {
        lat: 29.0374499,
        lng: -110.9583478,
      },
      image: Image531,
      code: "E01-HMO-53-1",
      size: "12.90 X 7.20",
      pdf: Pdf531,
    },
    {
      id: 46,
      location: {
        lat: 29.0374499,
        lng: -110.9583478,
      },
      image: Image532,
      code: "E01-HMO-53-2",
      size: "12.90 X 7.20",
      pdf: Pdf532,
    },
    {
      id: 47,
      location: {
        lat: 29.105802,
        lng: -110.951282,
      },
      image: Image561,
      code: "E01-HMO-56-1",
      size: "12.90 x 7.20",
      pdf: Pdf561,
    },
    {
      id: 48,
      location: {
        lat: 29.105802,
        lng: -110.951282,
      },
      image: Image562,
      code: "E01-HMO-56-2",
      size: "12.90 x 7.20",
      pdf: Pdf562,
    },
    {
      id: 49,
      location: {
        lat: 29.110234,
        lng: -110.964661,
      },
      image: Image571,
      code: "E01-HMO-57-1",
      size: "12.90 x 5.50 ",
      pdf: Pdf571,
    },
    {
      id: 50,
      location: {
        lat: 29.110234,
        lng: -110.964661,
      },
      image: Image572,
      code: "E01-HMO-57-2",
      size: "12.90 x 5.50 ",
      pdf: Pdf572,
    },
    {
      id: 51,
      location: {
        lat: 29.1446987,
        lng: -110.9892451,
      },
      image: Image591,
      code: "E01-HMO-59-1",
      size: "10.00 x 6.00",
      pdf: Pdf591,
    },
    {
      id: 52,
      location: {
        lat: 29.1446987,
        lng: -110.9892451,
      },
      image: Image592,
      code: "E01-HMO-59-2",
      size: "10.00 x 6.00",
      pdf: Pdf592,
    },
    {
      id: 53,
      location: {
        lat: 27.0727573,
        lng: -109.4437493,
      },
      image: Image611,
      code: "E01-HMO-61-1",
      size: "12.90 X 7.20",
      pdf: Pdf611,
    },
    {
      id: 54,
      location: {
        lat: 27.0727573,
        lng: -109.4437493,
      },
      image: Image612,
      code: "E01-HMO-61-2",
      size: "12.90 X 7.20",
      pdf: Pdf612,
    },
    {
      id: 55,
      location: {
        lat: 20.6760832,
        lng: -101.3361631,
      },
      image: Image641,
      code: "E01-HMO-64-1",
      size: "12.90 x 7.20",
      pdf: Pdf641,
    },
    {
      id: 56,
      location: {
        lat: 20.6760832,
        lng: -101.3361631,
      },
      image: Image642,
      code: "E01-HMO-64-2",
      size: "12.90 x 7.20",
      pdf: Pdf642,
    },
    {
      id: 57,
      location: {
        lat: 29.0816251,
        lng: -111.0223114,
      },
      image: Image661,
      code: "E01-HMO-66-1",
      size: "12.90 X 7.20",
      pdf: Pdf661,
    },
    {
      id: 58,
      location: {
        lat: 29.0816251,
        lng: -111.0223114,
      },
      image: Image662,
      code: "E01-HMO-66-2",
      size: "12.90 X 7.20",
      pdf: Pdf662,
    },
    {
      id: 59,
      location: {
        lat: 23.0303212,
        lng: -99.1479371,
      },
      image: Image721,
      code: "E01-HMO-72-1",
      size: "12.00 x 7.20",
      pdf: Pdf721,
    },
    {
      id: 60,
      location: {
        lat: 23.0303212,
        lng: -99.1479371,
      },
      image: Image722,
      code: "E01-HMO-72-2",
      size: "12.00 x 7.20",
      pdf: Pdf722,
    },
    {
      id: 61,
      location: {
        lat: 29.1124495,
        lng: -110.9510218,
      },
      image: Image751,
      code: "E01-HMO-75-1",
      size: "12.90 x 7.20",
      pdf: Pdf751,
    },
    {
      id: 62,
      location: {
        lat: 29.1124495,
        lng: -110.9510218,
      },
      image: Image752,
      code: "E01-HMO-75-2",
      size: "12.90 x 7.20",
      pdf: Pdf752,
    },
    {
      id: 63,
      location: {
        lat: 29.1266094,
        lng: -110.9515003,
      },
      image: Image021,
      code: "E04-HMO-02-1",
      size: "12.00 x 8.00",
      pdf: Pdf021,
    },
    {
      id: 64,
      location: {
        lat: 29.1266094,
        lng: -110.9515003,
      },
      image: Image022,
      code: "E04-HMO-02-2",
      size: "12.00 x 8.00",
      pdf: Pdf022,
    },
    {
      id: 65,
      location: {
        lat: 29.1129545,
        lng: -110.9812135,
      },
      image: Image051,
      code: "E04-HMO-05-1",
      size: "12.00 x 8.00",
      pdf: Pdf051,
    },
    {
      id: 66,
      location: {
        lat: 29.1129545,
        lng: -110.9812135,
      },
      image: Image052,
      code: "E04-HMO-05-2",
      size: "12.00 x 8.00",
      pdf: Pdf052,
    },
    {
      id: 67,
      location: {
        lat: 29.085241,
        lng: -110.957928,
      },
      image: Image031,
      code: "E08-HMO-03-1",
      size: "10.00 X 6.00",
      pdf: Pdf031,
    },
    {
      id: 68,
      location: {
        lat: 29.085241,
        lng: -110.957928,
      },
      image: Image032,
      code: "E08-HMO-03-2",
      size: "10.00 X 6.00",
      pdf: Pdf032,
    },
    {
      id: 69,
      location: {
        lat: 29.1458895,
        lng: -110.953474,
      },
      image: Image011DOS,
      code: "E08-HMO-01-1",
      size: "12.90 x 7.20",
      pdf: Pdf011DOS,
    },
    {
      id: 70,
      location: {
        lat: 29.1458895,
        lng: -110.953474,
      },
      image: Image012DOS,
      code: "E08-HMO-01-2",
      size: "12.90 x 7.20",
      pdf: Pdf012DOS,
    },
    {
      id: 73,
      location: {
        lat: 29.0674055,
        lng: -110.9551791,
      },
      image: Image061DOS,
      code: "E08-HMO-06-1",
      size: "12.90 x 7.20 ",
      pdf: Pdf061DOS,
    },
    {
      id: 74,
      location: {
        lat: 29.0674055,
        lng: -110.9551791,
      },
      image: Image062DOS,
      code: "E08-HMO-06-2",
      size: "12.90 x 7.20 ",
      pdf: Pdf062DOS,
    },
    {
      id: 75,
      location: {
        lat: 29.095022,
        lng: -111.015969,
      },
      image: Image071DOS,
      code: "E08-HMO-07-1",
      size: "12.90 X 7.20",
      pdf: Pdf071DOS,
    },
    {
      id: 76,
      location: {
        lat: 29.095022,
        lng: -111.015969,
      },
      image: Image072DOS,
      code: "E08-HMO-07-2",
      size: "12.90 X 7.20",
      pdf: Pdf072DOS,
    },
    {
      id: 77,
      location: {
        lat: 29.057476,
        lng: -110.965384,
      },
      image: Image101DOS,
      code: "E08-HMO-10-1",
      size: "12.90 X 7.20",
      pdf: Pdf101DOS,
    },
    {
      id: 78,
      location: {
        lat: 29.057476,
        lng: -110.965384,
      },
      image: Image102DOS,
      code: "E08-HMO-10-2",
      size: "12.90 X 7.20",
      pdf: Pdf102DOS,
    },
    {
      id: 79,
      location: {
        lat: 29.1088886,
        lng: -110.9513472,
      },
      image: Image111,
      code: "E08-HMO-11-1",
      size: "12.90 x 7.20",
      pdf: Pdf111,
    },
    {
      id: 80,
      location: {
        lat: 29.1088886,
        lng: -110.9513472,
      },
      image: Image112,
      code: "E08-HMO-11-2",
      size: "12.90 x 7.20",
      pdf: Pdf112,
    },
    {
      id: 81,
      location: {
        lat: 29.0875643,
        lng: -110.9767219,
      },
      image: Image121DOS,
      code: "E08-HMO-12-1",
      size: "12.90 x 7.20",
      pdf: Pdf121DOS,
    },
    {
      id: 82,
      location: {
        lat: 29.0875643,
        lng: -110.9767219,
      },
      image: Image122,
      code: "E08-HMO-12-2",
      size: "12.90 x 7.20",
      pdf: Pdf122,
    },
    {
      id: 83,
      location: {
        lat: 29.093787,
        lng: -110.955769, // TODO
      },
      image: Image000,
      code: "MARK-HMO",
      size: "11.00 x 4.00",
      pdf: Pdf000,
    },
    {
      id: 84,
      location: {
        lat: 29.089659,
        lng: -111.047082,
      },
      image: Image005,
      code: "PMK-HMO-005",
      size: "12.90 x 7.20",
      pdf: Pdf005,
    },
  ];

  const handleMarkerClick = useCallback(
    (marker) => {
      if (selectedMarker && selectedMarker.id === marker.id) {
        setSelectedMarker(null);
        return;
      }
      setSelectedMarker(marker);
      setCenter(marker.location);
    },
    [selectedMarker]
  );

  const downloadPdf = (pdf) => {
    window.open(pdf, "_blank");
  };

  const PinModal = () => {
    const { image, code, size, pdf } = selectedMarker || {};

    if (!selectedMarker) return null; // Early return if no selectedMarker

    return (
      <div className="pinModal p-2 d-flex flex-column justify-content-between align-items-start info-box open">
        <img className="img-fluid mb-3" src={image} alt={code} loading="lazy" />
        <p>
          <span className="me-1">{t("code")}:</span>
          {code}
        </p>
        <p>
          <span className="me-1">{t("size")}:</span>
          {size}
        </p>
        <button className="btn" type="button" onClick={() => downloadPdf(pdf)}>
          {t("downloadPdf")}
        </button>
      </div>
    );
  };

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <ScrollAnimation
      id="map"
      className="container pb-5 d-flex flex-column justify-content-center align-items-center"
      ariaLabelledBy="map-title"
    >
      <h2 className="text-center">
        {t("mapTitle")} <span>{t("mapTitle2")}</span>
      </h2>
      <p>{t("mapDescription")}</p>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={13}
        center={center}
        options={mapOptions}
        onIdle={() => setCenter(center)}
      >
        {billboardLocations.map((marker) => (
          <MarkerF
            key={marker.id}
            position={marker.location}
            onClick={() => handleMarkerClick(marker)}
            icon={{
              url: PinIcon,
              scaledSize: new window.google.maps.Size(40, 40),
            }}
          />
        ))}
        {selectedMarker && (
          <InfoBox
            key={selectedMarker.id}
            position={selectedMarker.location}
            onCloseClick={() => setSelectedMarker(null)}
            options={{
              closeBoxURL: "",
              enableEventPropagation: true,
              boxStyle: {
                width: "15rem",
                padding: "0",
                borderRadius: "5px",
              },
              pixelOffset: new window.google.maps.Size(-120, 5),
            }}
          >
            <PinModal />
          </InfoBox>
        )}
      </GoogleMap>
    </ScrollAnimation>
  );
}
